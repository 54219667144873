<template>
  <div id="app">
    <!-- <a-affix :offset-top="top"> -->
      <NavHeader class="nav-header" @changeDemoShow="changeDemoShowFn" v-if="isPoster"></NavHeader>
    <!-- </a-affix> -->
    <WatchDemo v-if="isPoster"></WatchDemo>
    <div class="image" v-if="isPoster">
      <img src="@/assets/EXW_Local_Manager/kefu.png" alt="" @mouseenter="infoShow=true" @mouseleave="infoShow=false"/>
      <div  class="background"  v-show="infoShow">
      <img src="@/assets/EXW_Local_Manager/beijing(1).png" alt="">
      <div class="info">
        <img src="@/assets/EXW_Local_Manager/dianhua.png" alt="">
        <span>Tel no.:</span>
        <div class="email">+86 21 60145999<br>+86 21 60145998</div>
        <hr style="width: 180px; border: none; border-top: 1px solid #dcdbd9;">
        <img src="@/assets/EXW_Local_Manager/youxiang.png" alt="" style="margin-right: 10px">
        <span>Email:</span>
        <div class="email" style="margin-bottom: 10px">marketing@duckbillscm.com</div>
      </div>
      </div>
    </div>
    <router-view />
    <Footer v-if="isPoster"></Footer>
    <a-back-top v-show="true"/>
  </div>
</template>
<script>
import NavHeader from '@/components/NavHeader'
import Footer from '@/components/Footer'
import WatchDemo from './components/WatchDemo.vue'
export default {
  components: {
    NavHeader,
    Footer,
    WatchDemo
  },
  data () {
    return {
      demoShow: '',
      infoShow: false,
      isPoster: true
    }
  },
  methods: {
    changeDemoShowFn (data) {
      console.log(111)
    }
  },
  watch: {
    $route: function (to, from) {
      // 在这里执行当路由改变时需要进行的操作
      console.log('路由已改变', to, from)
      if (to.name === 'Poster' || to.name === 'PosterCN') {
        this.isPoster = false
      }
    }
    // changeDemoShow () {
    //   this.$on('changeDemoShow', (value) => {
    //     console.log(value)
    //   })
    // }
  }
}
</script>
<style lang="less">
@import url("./style/reset.less");
@import url("./style/common.less");
@import url("./style/mixin.less");
#app {
  width: 100%;
  height: 100%;
  overflow: auto;
  .ant-back-top{
    right: 32px;
    bottom: 100px;
  }
  .ant-back-top-content{
    background: #ff9100;
  }
  position: relative;
  .nav-header {
    width: 100%;
    background-color: #fff;
    position: absolute;
    // left: 50%;
    // transform: translate(-50%, 0);
    z-index: 999;
  }
  .image {
    position: fixed;
    z-index: 9999;
    // top: 560px;
    bottom: 200px;
    // top: calc(100% - 390px);
    right: 30px;
    .background {
      position: absolute;
      left: -237px;
      top: -3px;
      .info {
        position: absolute;
        top: 15px;
        left: 30px;
        span {
          font-weight: 700;
        }
        img {
          margin-right: 5px;
        }
        .email {
          margin-top: 5px;
          color: #FF9100;
        }
      }
    }
  }
  .ant-back-top {
    // position: unset;
  }
}
     #nprogress .bar {
      background: #FF9100 !important; //自定义颜色
      height: 3px !important;
    }
</style>
