<template>
  <div class="video-box" v-show="showSlide">
    <div class="overlay" v-if="showSlide == 'slideDown'"></div>
    <div class="video" :class="showSlide" ref="videobox">
      <div class="icon-close" @click="closeVideo">X</div>
      <video
        width="1200"
        height="590"
        loop
        controls
        poster=""
        ref="video"
      >
        <source src="@/assets/watchDemo.mp4" type="video/mp4" />
        Your browser doesn't support HTML5 video.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  // props: ['showSlide'],
  data () {
    return {
      showSlide: ''
    }
  },
  mounted () {
    this.$EventBus.$on('changeDemoShow', (flag) => {
      console.log(flag)
      this.showSlide = flag
      setTimeout(() => {
        this.$refs.video.load()
      }, 200)
    })
  },
  methods: {
    closeVideo () {
      this.$refs.video.pause()
      this.showSlide = 'slideUp'
      setTimeout(() => {
        this.showSlide = ''
      }, 600)
    }
  }
}
</script>

<style lang="less" scoped>
.video-box {
  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #333;
    opacity: 0.4;
    z-index: 1000;
  }
  // @keyframes定义动画,是成对的,例如淡入淡出
  @keyframes slideDown {
    from {
      top: -50%;
      opacity: 0;
    }
    to {
      top: 50%;
      opacity: 1;
    }
  }
  @keyframes slideUp {
    from {
      top: 50%;
      opacity: 1;
    }
    to {
      top: -50%;
      opacity: 0;
    }
  }
  .video {
    position: fixed;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    width: 1000px;
    height: 536px;
    // opacity: 0;
    opacity: 1;
    /*  transition: all 0.6s;
          &.slide {
            top: 50%;
            opacity: 1;
          } */
    &.slideDown {
      animation: slideDown 0.6s linear;
      top: 50%;
    }
    &.slideUp {
      animation: slideUp 0.6s linear;
    }
    .icon-close {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 30px;
      color: #fff;
      // background-color: #fff;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 110;
      color: #000;
    }
    video {
      width: 100%;
      height: 100%;
      // 去掉原生阴影
      object-fit: cover;
      outline: none;
    }
  }
}
</style>
