import Vue from 'vue'
import Antd, { Button, message } from 'ant-design-vue'
import App from './App.vue'
// import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/dist/antd.less'
import router from './router'
import store from './store'

Vue.config.productionTip = false

const testConfigUrl = [
  'qa.yazuishou',
  'qa.duckbillscm.com',
  'vpc.duckbillscm.com',
  'vpc.yazuishou.com',
  'uatm.yazuishou.com',
  'qaen.duckbillscm.com'
]

if (window.location.origin.indexOf('en.duckbillscm.com') > 0) {
  (function () {
    // 测试环境，禁止百度统计
    testConfigUrl.forEach(item => {
      if (window.location.href.indexOf(item) > 0) {

      }
    })
    var hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?047661cbf39077191ce8e6c96b300594'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
}

Vue.use(Antd)

Vue.use(Button)

Vue.prototype.$message = message

Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
