<template>
  <div class="header">
    <div class="container">
      <div class="logo">
        <img src="../assets/EXW_Local_Manager/logo.png" alt="" />
      </div>
      <div class="menu">
        <div
          class="item"
          v-for="item in menuData"
          :key="item.id"
          :class="{ active: $route.path === item.path }"
          @click="$router.push(item.path)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="language-transform">
        <div class="cn common" @click="toChinese">中</div>
        <div class="en common">En</div>
        <!-- <a-radio-group v-model="value" button-style="solid" @change="onChange">
        <a-radio-button value="cn">
          中
        </a-radio-button>
        <a-radio-button value="en">
          En
        </a-radio-button>
      </a-radio-group> -->
      </div>
      <div class="watch-demo" @click="changeDemoShow">
        <!-- <img src="../assets/EXW_Local_Manager/watch-demo.png" alt="" /> -->
        <svg style="vertical-align: text-bottom;" t="1656566949323" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8560" width="20" height="20"><path d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 981.333333C253.866667 981.333333 42.666667 770.133333 42.666667 512S253.866667 42.666667 512 42.666667s469.333333 211.2 469.333333 469.333333-211.2 469.333333-469.333333 469.333333z" p-id="8561"></path><path d="M672 441.6l-170.666667-113.066667c-57.6-38.4-106.666667-12.8-106.666666 57.6v256c0 70.4 46.933333 96 106.666666 57.6l170.666667-113.066666c57.6-42.666667 57.6-106.666667 0-145.066667z" p-id="8562"></path></svg>        <span >Watch Demo</span>
      </div>
    </div>
  </div>
</template>

<script>
import { menuData } from './data'
// import WatchDemo from './WatchDemo.vue'
export default {
  name: 'NavHeader',
  data () {
    return {
      activeIndex: -1,
      menuData,
      value: 'en'
      // demoShow: ''
    }
  },
  // components: {
  //   WatchDemo
  // },
  methods: {
    toChinese () {
      // console.log(process.env.NODE_ENV)
      // if (process.env.NODE_ENV === 'production') {
      //   window.location.href = 'https://duckbillscm.com/index?entozh=true'
      // } else {
      //   window.location.href = 'http://duckbillscm.fat.driverglobe.com/index'
      // }
      if (window.location.host === 'qaen.duckbillscm.com' || window.location.host === 'local.fat.driverglobe.com:8888') {
        window.location.href = 'http://duckbillscm.fat.driverglobe.com/index'
      } else {
        window.location.href = 'https://yazuishou.com/index?entozh=true'
      }
    },
    changeDemoShow () {
      this.$EventBus.$emit('changeDemoShow', 'slideDown')
    }
  }
}
</script>

<style lang='less' scoped>
.header {
  font-size: 14px;
  color: rgb(164, 164, 164);
  font-family: Microsoft YaHei;
  .container {
    height: 46px;
    display: flex;
    align-items: center;
    .logo {
      margin-right: 101px;
    }
    .menu {
      display: flex;
      .item {
        margin-right: 29px;
        cursor: pointer;
        &:hover {
          color: #ff9100;
        }
      }
      .active {
        color: #ff9100;
      }
    }
    .language-transform {
      margin-left: 227px;
      display: flex;
        border: 1px solid #c7b9a6;
        cursor: pointer;
      .common {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: transparent;
        color: #C7B9A6;
        &.en {
          background-color: #FF9100;
          color: #fff;
        }
      }
    }
    .watch-demo {
      margin-left: 50px;
      cursor: pointer;
      img {
        margin-right: 11px;
      }
    }
  }
}

.ant-radio-button-wrapper {
  height: 30px;
  padding: 0 9px;
  background-color: transparent;
  color: #c7b9a6;
  border: 1px solid #c7b9a6;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #ff9100;
  background: #fff;
  border: 1px solid #c7b9a6;
}
</style>
