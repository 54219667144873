<template>
  <div class="footer">
    <div class="container">
      <div class="copy-right">
        ©2022 Duckbill.All Rights Reserved.
        <div class="link">
          <span v-for="item in hoverData" :key="item.id">
            <a-popover :key="item.id">
              <template slot="content">
                <p>WeChat ID:Duckbill</p>
                <img style="width: 150px;" :src="item.content" />
              </template>
              <img
                :src="activeIndex===item.id ? item.activeImgUrl : item.imgUrl"
                alt=""
                :key="item.id"
                @mouseenter="mouseEnter(item)"
                @mouseleave="activeIndex=-1"
                :style="{'margin-right': item.id === 3 ? '5px' : ''}"
              >
            </a-popover>
          </span>
          <img
            :src="activeIndex===item.id ? item.activeImgUrl : item.imgUrl"
            alt=""
            v-for="item in linkData"
            :key="item.id"
            @mouseenter="mouseEnter(item)"
            @mouseleave="activeIndex=-1"
            :style="{'margin-right': item.id === 3 ? '5px' : ''}"
            @click="clickItem(item.link)"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { linkData, hoverData } from './data'
export default {
  data () {
    return {
      linkData,
      hoverData,
      activeIndex: -1
    }
  },
  methods: {
    clickItem (link) {
      window.open(link)
    },
    mouseEnter (item) {
      this.activeIndex = item.id
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  background-color: #F6F6F6;
  height: 88px;
  line-height: 88px;
  .copy-right {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    .link {
      cursor: pointer;
    }
  }
}

</style>
