export const menuData = [
  {
    id: 1,
    text: 'EXW Local Manager',
    path: '/exw-local-manager'
  },
  {
    id: 2,
    text: 'Case Study',
    path: '/case-study'
  },
  {
    id: 3,
    text: 'About Us',
    path: '/about-us'
  },
  {
    id: 4,
    text: 'Contact Us',
    path: '/contact-us'
  }
]

export const linkData = [
  {
    id: 2,
    imgUrl: require('@/assets/EXW_Local_Manager/link/link.png'),
    activeImgUrl: require('@/assets/EXW_Local_Manager/link/cheng/link.png'),
    link: 'https://www.linkedin.com/company/31498300/admin/'
  },
  {
    id: 1,
    imgUrl: require('@/assets/EXW_Local_Manager/link/facebook.png'),
    activeImgUrl: require('@/assets/EXW_Local_Manager/link/cheng/facebook.png'),
    link: 'https://www.facebook.com/Duckbill-101324015794768/?ref=pages_you_manage'
  }
  // {
  //   id: 3,
  //   imgUrl: require('@/assets/EXW_Local_Manager/link/youtube.png'),
  //   activeImgUrl: require('@/assets/EXW_Local_Manager/link/cheng/youtube.png'),
  //   link: ''
  // },
]
export const hoverData = [
  {
    id: 4,
    imgUrl: require('@/assets/EXW_Local_Manager/link/weixin.png'),
    activeImgUrl: require('@/assets/EXW_Local_Manager/link/cheng/weixin.png'),
    link: '',
    content: require('@/assets/Footer/wechat.png')
  }
]
