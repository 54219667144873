import Vue from 'vue'
import VueRouter from 'vue-router'
// import EXWLocalManager from '../views/EXWLocalManager/EXWLocalManager'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: '/exw-local-manager' }
    }
  },
  {
    path: '/exw-local-manager',
    name: 'EXWLocalManager',
    component: () => import(/* webpackChunkName: "about" */ '../views/EXWLocalManager/EXWLocalManager.vue')
  },
  {
    path: '/case-study',
    name: 'CaseStudy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CaseStudy/CaseStudy.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs/AboutUs.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs/ContactUs.vue')
  },
  {
    path: '/poster',
    name: 'Poster',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Poster/Poster.vue')
  },
  {
    path: '/posterCN',
    name: 'PosterCN',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PosterCN/PosterCN.vue')
  }
]

const router = new VueRouter({
  routes
})

// router.beforEach((to, from, next) => {
//   if (to.meta.title) {
//     document.title = to.meta.title
//   }
// })

export default router
